import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../components/button';
import {WillAnimate} from '../../components/content_animate';
import {Cta} from '../../components/cta';
import {Layout} from '../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Our portfolio of software products"
        description="Different examples of our work. Here an overview of the web platforms, native apps and SaaS products that we developed for our clients."
        ogImage="portfolio.png"
        translatedPagePath="/nl/portfolio/"
        locale={locale}>
        <section className="content content--no-hero">
            <article className="container">
                <Link
                    to="/en/portfolio/saas-product-keeping"
                    className="case-item-wrapper"
                    title="Case: Our own product: Keeping">
                    <WillAnimate as="div" className="case-item case-item--odd">
                        <div className="case-item__image-wrapper">
                            <Img alt="Keeping" fluid={data.keepingBarend.childImageSharp.fluid} />
                        </div>
                        <div className="case-item__content">
                            <div className="case-item__title">
                                <h2>Our own SaaS product: Keeping</h2>
                            </div>
                            <div className="case-item__subtitle">
                                <h3>Effortless time-tracking. Every moment of the day.</h3>
                            </div>
                            <Button name="View this case" />
                        </div>
                    </WillAnimate>
                </Link>
            </article>

            <article className="container">
                <Link
                    to="/en/portfolio/translation-platform-fairlingo/"
                    className="case-item-wrapper"
                    title="Case: Translation platform Fairlingo">
                    <WillAnimate as="div" className="case-item case-item--even">
                        <div className="case-item__image-wrapper">
                            <Img alt="Fairlingo" fluid={data.fairlingo.childImageSharp.fluid} />
                        </div>
                        <div className="case-item__content">
                            <div className="case-item__title">
                                <h2>Fairlingo translation platform</h2>
                            </div>
                            <div className="case-item__subtitle">
                                <h3>The Uber of translation.</h3>
                            </div>
                            <Button name="View this case" />
                        </div>
                    </WillAnimate>
                </Link>
            </article>

            <article className="container">
                <Link
                    to="/en/portfolio/valuation-platform-taxapi/"
                    className="case-item-wrapper"
                    title="Case: Valuation platform TaXapi">
                    <WillAnimate as="div" className="case-item case-item--odd">
                        <div className="case-item__image-wrapper">
                            <Img alt="Valuation platform TaXapi in use" fluid={data.taxapi.childImageSharp.fluid} />
                        </div>
                        <div className="case-item__content">
                            <div className="case-item__title">
                                <h2>Property valuation with TaXapi</h2>
                            </div>
                            <div className="case-item__subtitle">
                                <h3>Everything for making valuations within reach.</h3>
                            </div>

                            <Button name="View this case" />
                        </div>
                    </WillAnimate>
                </Link>
            </article>
        </section>

        <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
        keepingBarend: file(relativePath: {eq: "portfolio/keeping/keeping-barend.jpg"}) {
            ...fluid1200Width
        }
        taxapi: file(relativePath: {eq: "portfolio/taxapi/taxapi-main.jpg"}) {
            ...fluid1200Width
        }
        fairlingo: file(relativePath: {eq: "portfolio/fairlingo/fairlingo.jpg"}) {
            ...fluid1200Width
        }
    }
`;
